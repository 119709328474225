import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementUI from 'element-ui'
import Vant from 'vant'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/qj.css'
import 'vant/lib/index.css'
import Cookies from 'js-cookie'

import './assets/style/variables.less'

import YtInputNumber from '@/components/YtInputNumber'

import request from '@/utils/request'
import { baseImageUrl } from '@/utils/common'

Vue.use(ElementUI)
Vue.use(Vant)
Vue.prototype.$http = request
Vue.prototype.$baseImageUrl = baseImageUrl

// 全局
Vue.prototype.$logotitle = '强多多'
Vue.prototype.$gourl = 'https://www.catduoduo.com/#/index'

// 全局

Vue.config.productionTip = false

Vue.component('YtInputNumber', YtInputNumber)

const TOKEN = () => {
	return Cookies.get('mb_TOKEN')
}
if (TOKEN()) {
	// store.dispatch('fetchMenuList')
	store.dispatch('updataUserInfo')
}
// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
	//wph2023-03-03 15:12 start 缓存o和t
	var url = window.location.href
	if (url.indexOf('?') != -1) {
		let str = url.split('?')[1]
		var myarrs = str.split('&')
		for (var i = 0; i < myarrs.length; i++) {
			var key = myarrs[i].split('=')[0]
			var key_v = myarrs[i].split('=')[1]
			if (key == 'o') {
				let time = parseInt(new Date().getTime() / 1000) + ''
				localStorage.setItem('o', key_v)
				localStorage.setItem('t', time)
			}
			if (key == 'inCode') {
				localStorage.setItem('inCode', key_v)
			}
		}
	}
	//wph2023-03-03 end
	// 判断该路由是否需要登录权限
	if (
		to.path == '/login' ||
		to.path == '/register' ||
		to.path == '/forgetPass' ||
		to.path == '/login/orderService' ||
		to.path == '/codeView' ||
		to.path == '/guiplat/shareSupply' ||
		to.path == '/guiplat/orderService' ||
		to.path == '/guiplat/anonymousOrderService' ||
		to.path == '/guiplat/chatGPT' ||
		to.path == '/service/anonymousOrderService' ||
		to.path == '/service/anonymousOrderSS2Service' ||
		to.path == '/courseDetailed' ||
		to.path == '/guiplat/orderSS2Service' ||
		to.path == '/guiplat/courseDetailed' ||
		to.path == '/guiplat/orderSS4Service'
	) {
		next()
	} else {
		let Authorization = TOKEN()
		if (Authorization) {
			next()
		} else {
			next({
				path: '/login',
				// 将跳转的路由path作为参数，登录成功后跳转到该路由
				query: { redirect: to.fullPath },
			})
		}
	}
})

// 跳转后返回顶部
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
